<template>
    <div class="responsive">
        <div class="mod">
            <transaction-processing
                :showPendingClaim="showPendingClaim"
                :showSuccessClaim="showSuccessClaim"
                :showSmallBalance="showSmallBalance"
                :claimTransactionHash="claimTransactionHash"
                :stakeAmount="stakeAmount"
                :tokenName="tokenName"
                @close="
                    (showPendingClaim = false),
                        (showSuccessClaim = false),
                        (showSmallBalance = false),
                        (claimTransactionHash = ''),
                        (tokenName = ''),
                        (stakeAmount = 0)
                "
            />
            <div class="modal__inner choose-wallet">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("CLAIM_CONFIRM") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>

                <div v-if="stake.farmTokensList && stake.farmTokensList.length === 1" class="modal__down metamask-wallet">
                    <div class="first__block">
                        <p class="could__get"></p>
                        <div class="row__days__text claim-get">
                            {{ lang.get("COULD_GET") }}
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("COULD_GET_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="unstake__big">
                        {{ getSingleTokenReward(stake.farmTokensReward[0], false) }} <span>{{ tokenTicker(stake.name) }}</span>
                    </p>
                    <div v-if="getPenaltyPercent > 0" class="warning">
                        <div class="warr"><i class="i-error-warning-fill"></i></div>
                        <div class="warrning-block">
                            <p class="warning__text">{{ lang.get("PENALTY_DESC_CLAIM") }} {{ getPenaltyDateUntil(stake) }}</p>
                            <a class="learn__more" href="https://bringfinance.medium.com/how-to-earn-max-rewards-and-avoid-penalties-staking-e77a017b4849">{{
                                lang.get("LEARN_MORE")
                            }}</a>
                        </div>
                    </div>
                    <div class="penalty__block">
                        <div class="penalty__block__text">
                            <p class="penalty__text">{{ lang.get("PenaltyPercent") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("PENALTY_PERC_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="penalty__number">{{ getPenaltyPercent >= 0 ? getPenaltyPercent.toFixed(2) : 0 }}%</p>
                    </div>
                    <div class="first__block">
                        <p class="could__get">{{ lang.get("EXPECTED_REWARD") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("GET_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="unstake__big">
                        {{ getSingleTokenReward(stake.farmTokensReward[0]) }}<span>{{ tokenTicker(stake.farmTokensList[0].name) }}</span>
                    </p>
                    <div v-if="getPenaltyPercent > 0" class="modal__checkbox">
                        <input class="custom-checkbox" type="checkbox" id="myCheckbox1" v-model="acceptedPenalty" />
                        <label for="myCheckbox1" class="modal__text"> {{ lang.get("CHECK_UNSTAKE") }}</label>
                    </div>
                    <button @click="claimReward(stake)" :disabled="!acceptedPenalty" class="make__stake">claim</button>
                </div>
                <div v-else class="modal__down">
                    <div class="modal__reward__you">
                        <div class="name__reward__you">
                            <p class="your__reward">{{ lang.get("EXPECTED_REWARD") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("REWARD_AMOUNT_?") }}
                                </span>
                            </div>
                        </div>
                        <div class="many__name">
                            <div v-for="(farmToken, index) in stake.farmTokensReward" :key="stake.farmTokensList[index].address" class="first__many__name">
                                <div class="block__for__many">
                                    <span class="ball__many" :class="stake.farmTokensList[index].name"></span>
                                    <p class="many__name__name">{{ tokenTicker(stake.farmTokensList[index].name) }}</p>
                                </div>
                                <p class="simple__text">{{ getSingleTokenReward(farmToken) }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="getPenaltyPercent > 0" class="warning">
                        <div class="warr"><i class="i-error-warning-fill"></i></div>
                        <div class="warrning-block">
                            <p class="warning__text">{{ lang.get("PENALTY_DESC_CLAIM") }} {{ getPenaltyDateUntil(stake) }}</p>
                            <a href="https://bringfinance.medium.com/how-to-earn-max-rewards-and-avoid-penalties-staking-e77a017b4849">{{
                                lang.get("LEARN_MORE")
                            }}</a>
                        </div>
                    </div>
                    <div class="penalty__block">
                        <div class="penalty__block__text">
                            <p class="penalty__text">{{ lang.get("PenaltyPercent") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("PENALTY_PERC_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="penalty__number">{{ getPenaltyPercent >= 0 ? getPenaltyPercent.toFixed(2) : 0 }}%</p>
                    </div>
                    <div v-if="stake.farmTokensList && stake.farmTokensList.length > 1" class="earn__coust">
                        <div v-if="getPenaltyPercent > 0">
                            <div class="coust__name">
                                <p class="coust__text">{{ lang.get("YOU_EARN_LESS") }}</p>
                                <div class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("LESS_?") }}
                                    </span>
                                </div>
                            </div>
                            <p v-if="getPenaltyPercent > 0" class="coust__all"><span>$</span></p>
                            <p class="any__number">{{ getFarmTokensPrice(stake.farmTokensList) }} USD</p>
                        </div>
                    </div>

                    <div v-if="getPenaltyPercent > 0" class="modal__checkbox">
                        <input class="custom-checkbox" type="checkbox" id="myCheckbox1" v-model="acceptedPenalty" />
                        <label for="myCheckbox1" class="modal__text">{{ lang.get("CHECK_UNSTAKE") }}</label>
                    </div>

                    <button @click="claimReward(stake)" :disabled="acceptedPenalty === false" class="make__stake">Claim</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import MultiLang from "../../core/multilang";
    import conf from "../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import TransactionProcessing from "./TransactionProcessing.vue";
    export default {
        components: {
            TransactionProcessing,
        },
        props: ["stake"],
        data: function () {
            return {
                lang: new MultiLang(this),
                acceptedPenalty: false,
                showPendingClaim: false,
                showSuccessClaim: false,
                showSmallBalance: false,
                stakeAmount: 0,
                tokenName: "",
                claimTransactionHash: "",
            };
        },

        computed: {
            ...mapState(["penaltyInfo", "currentAddress", "currentRate", "userCoinBalance"]),
            getPenaltyPercent() {
                if (this.penaltyInfo[this.stake.contractId][this.stake.stakedTokenAddress.toLowerCase()]) {
                    return (
                        this.penaltyInfo[this.stake.contractId][this.stake.stakedTokenAddress.toLowerCase()].penaltyPercent /
                        Config.CONTRACT_VERSION_CONFIG[this.stake.contractId].PENALTY_PRECISION
                    );
                }
            },
        },
        mounted() {
            let _this = this;

            setTimeout(async function init() {
                if (_this.getPenaltyPercent > 0) {
                    _this.acceptedPenalty = false;
                } else {
                    _this.acceptedPenalty = true;
                }
            }, 300);
        },
        methods: {
            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },
            getTokenPrice(tokenAddress) {
                return this.currentRate && this.currentRate[tokenAddress.toLowerCase()] ? this.currentRate[tokenAddress.toLowerCase()] : 0; //TODO get price for correct token address
            },
            getFarmTokensPrice(farmTokensList) {
                if (this.getPenaltyPercent === 0) {
                    let total = 0;
                    for (let [index, tokenObj] of Object.entries(farmTokensList)) {
                        const rewardTokensAmount = Number(this.stake.farmTokensReward[index]);

                        total += rewardTokensAmount * Number(this.getTokenPrice(tokenObj.address));
                    }
                    return total.toFixed(2);
                } else {
                    let total = 0;
                    for (let [index, tokenObj] of Object.entries(farmTokensList)) {
                        let rewardTokensAmount = Number(this.stake.farmTokensReward[index]);
                        rewardTokensAmount *= (100 - this.getPenaltyPercent) / 100;

                        total += rewardTokensAmount * Number(this.getTokenPrice(tokenObj.address));
                    }
                    return total.toFixed(2);
                }
            },
            getSingleTokenReward(farmToken, withPenalty = true) {
                if (withPenalty && this.getPenaltyPercent > 0) {
                    return (farmToken *= (100 - this.getPenaltyPercent) / 100).toFixed(4);
                } else {
                    return farmToken.toFixed(4);
                }
            },

            // afterPenalty(amount){
            //     if(this.getPenaltyPercent === 0){
            //         return amount
            //     }
            //     return amount *= (100 - )
            // }
            async claimReward(stake) {
                try {
                    const res = await this.$root.core.estimateGas("claimReward", stake.contractId, {
                        stakeId: stake.stakeIdx,
                    });
                    const fee = (Number(res) * Config.DEFAULT_GAS_PRICE_GWEI * 10 ** 9) / 10 ** 18;

                    if (fee > this.userCoinBalance) {
                        this.showSmallBalance = true;
                        return;
                    }
                    this.showPendingClaim = true;
                    let rawClaimTransaction = await this.$root.core.claimReward(stake.stakeIdx, stake.contractId);
                    this.stakeAmount = stake.tokenAmount;
                    this.tokenName = stake.name;

                    const address = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_ADDRESS.toLowerCase();
                    this.$root.core.forceRestart(this.currentBlockchain, address);
                    let resultClaim = await rawClaimTransaction.wait();

                    if (resultClaim.status === true || resultClaim.status === 1 || resultClaim.status === "1") {
                        window.localStorage.removeItem("stakeInfoTime");
                        setTimeout(() => {
                            this.$emit("close");
                        }, 5000);
                        this.showPendingClaim = false;
                        this.claimTransactionHash = resultClaim.transactionHash;
                        this.showSuccessClaim = true;
                    } else {
                        this.showPendingClaim = false;
                        this.showError = true;
                    }
                } catch (error) {
                    console.log(error);
                    this.showPendingClaim = false;
                    this.showError = true;
                }
            },
            getPenaltyDuration(stake) {
                if (this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()]) {
                    return this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft > 0
                        ? Math.round(this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft / 24 / 3600)
                        : "0";
                }
            },
            getPenaltyDateUntil(stake) {
                var dt = new Date();
                dt.setDate(dt.getDate() + this.getPenaltyDuration(stake));
                return dt.toLocaleDateString("en-UK");
            },
        },
    };
</script>
