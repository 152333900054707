<template>
    <div class="data__row row-m margin border stake__styles" :class="{ active_row: showStakeDetails && !stake.chainId }">
        <unstake-modal v-if="showUnstakeModal" @close="showUnstakeModal = false" :stake="stake" />
        <claim-modal v-if="showClaimModal" @close="showClaimModal = false" :stake="stake" />
        <div class="wrapper border" @click.stop="showStakeDetails = !showStakeDetails">
            <div class="token__stake-name">
                <span class="row__one__ball" :class="stake.name"></span>
                <p class="text__name__token">{{ tokenTicker(stake, stake.name) }} {{ stake.contractId.replace("N", "№") }}</p>
            </div>
            <div class="full__row desktop__row">
                <div class="row__one-stake">
                    <div>
                        <p class="row__apr">Stake amount</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text"> The amount of tokens for specific stake </span>
                        </div>
                    </div>
                    <p class="desktop__row">
                        {{ stake.tokenAmount ? Number(stake.tokenAmount).toFixed(4) : stake.stakeAmount ? Number(stake.stakeAmount).toFixed(4) : "0.0000" }}
                    </p>
                </div>
                <div class="row__five-stake">
                    <div class="row__five-inner">
                        <p class="row__reward">reward</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("REWARD_RECEIVED_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="balls">
                        <span v-for="farmToken in stake.farmTokensList" class="row__one__ball" :class="farmToken.name"></span>
                    </div>
                </div>
                <div class="row__unone-stake">
                    <div>
                        <p class="row__apr">APR</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("APR_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="desktop__row">{{ stakeAPYPercentage(stake) }}%</p>
                </div>

                <div v-if="stake.isActive" class="row__two-stake">
                    <div>
                        <p class="row__days">{{ lang.get("DAYS_STAKED") }}</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("DAYS_STAKED_STAKE_?") }}
                            </span>
                        </div>
                    </div>
                    <p v-if="stake.chainId" class="desktop__row">
                        {{
                            getTimePassed(stake.block_timestamp)[0] < getMaxStakePeriod(stake.contractId, stake)
                                ? getTimePassed(stake.block_timestamp)[0]
                                : getMaxStakePeriod(stake.contractId, stake)
                        }}&nbsp;{{ getTimePassed(stake.block_timestamp)[1] ? lang.get(`${getTimePassed(stake.block_timestamp)[1]}`) : "" }}&nbsp; /
                        {{ getMaxStakePeriod(stake.contractId, stake) }} days
                    </p>
                    <p v-else class="desktop__row">
                        {{
                            getTimePassed(stake.block_timestamp)[0] < getMaxStakePeriod(stake.contractId, stake)
                                ? getTimePassed(stake.block_timestamp)[0]
                                : getMaxStakePeriod(stake.contractId, stake)
                        }}&nbsp;{{ getTimePassed(stake.block_timestamp)[1] ? lang.get(`${getTimePassed(stake.block_timestamp)[1]}`) : "" }}&nbsp; /
                        {{ getMaxStakePeriod(stake.contractId, stake) }} days
                    </p>
                </div>
                <div v-else class="row__two">
                    <div class="selected-days">
                        <p class="row__days">{{ lang.get("DAYS_STAKED") }}</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("DAYS_STAKED_STAKE_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="desktop__row">
                        {{ getTimePassed(stake.stakeStartTime, stake.unstakeTime)[0] }}&nbsp;{{
                            getTimePassed(stake.stakeStartTime, stake.unstakeTime)[1]
                                ? lang.get(`${getTimePassed(stake.stakeStartTime, stake.unstakeTime)[1]}`)
                                : ""
                        }}
                        / {{ getMaxStakePeriod(stake.contractId, stake) }} days
                    </p>
                </div>
                <div class="row__five-stake five-blockchaim">
                    <div class="row__five-inner">
                        <p class="row__reward">Blockchain</p>
                        <div @click.stop="showStakeDetails = showStakeDetails" class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("REWARD_RECEIVED_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="balls">
                        <span
                            class="row__one__ball"
                            :class="{
                                'network-binance': stake.chainId ? Number(stake.chainId) === 56 : currentBlockchain === 56,
                                'network-ethereum': stake.chainId ? Number(stake.chainId) === 1 : currentBlockchain === 1,
                                'network-harmony': stake.chainId ? Number(stake.chainId) === 1666600000 : currentBlockchain === 1666600000,
                            }"
                        ></span>
                    </div>
                </div>
            </div>
            <button v-if="stake.chainId" @click.stop="changeNetwork(stake.blockchainSymbol)" class="for__hide_one change">Change Network</button>
            <div v-if="!stake.chainId" @click.stop="showStakeDetails = !showStakeDetails" class="left__line left-stake">
                <i class="i-arrow-drop-down-line"></i>
            </div>
            <button v-if="!stake.chainId" @click.stop="showStakeDetails = !showStakeDetails" class="for__more">
                {{ showStakeDetails === false ? "More" : "Hide" }}
            </button>
        </div>
        <div v-if="showStakeDetails" class="active__block">
            <div v-if="stake.isActive" class="under__data__row">
                <div class="claim">
                    <div class="claim__inner">
                        <div class="infrm">
                            <p class="claim__name">claim</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("CLAIM_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <div v-for="(farmToken, index) in stake.farmTokensReward" :key="index" class="claim__text">
                            <div class="claim__flex">
                                <span class="row__one__ball" :class="stake.farmTokensList[index].name"></span>
                                <p class="claim__bring">{{ tokenTicker(stake, stake.farmTokensList[index].name) }}</p>
                            </div>
                            <p class="simple__text">{{ farmToken >= 0 ? farmToken.toFixed(4) : "0.0000" }}</p>
                        </div>
                    </div>

                    <button @click="showClaimModal = true" class="claim__btn"><i class="i-rocket-fill"></i>claim</button>
                </div>

                <div class="unstake">
                    <div class="infrm">
                        <p class="unstake__name">unstake</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("UNSTAKE_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="unstake__big">
                        {{ stake.tokenAmount.toFixed(4) }} <span>{{ tokenTicker(stake, stake.name) }}</span>
                    </p>
                    <p class="unstake__text">{{ lang.get("UNSTAKE_DESC") }}</p>
                    <button @click="showUnstakeModal = true" class="unstake__btn">Unstake</button>
                </div>

                <div class="received">
                    <div class="infrm">
                        <p class="received__name">Received reward</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("Received_Reward_?") }}
                            </span>
                        </div>
                    </div>
                    <div v-for="farmToken in stake.farmTokensList" :key="farmToken.address" class="claim__text">
                        <div class="claim__flex">
                            <span class="row__one__ball" :class="farmToken.name"></span>
                            <p class="claim__bring">{{ tokenTicker(stake, farmToken.name) }}</p>
                        </div>
                        <p class="simple__text">
                            {{
                                findTokenReward(stake.stakedTokenAddress, farmToken.address, stake.stakeIdx, stake.contractId) >= 0
                                    ? findTokenReward(stake.stakedTokenAddress, farmToken.address, stake.stakeIdx, stake.contractId)
                                    : "0.0000"
                            }}
                        </p>
                    </div>

                    <hr />
                    <div class="unstake__sum">
                        <p class="last__unstake__text">approximate cost</p>
                        <p class="last__sum">
                            $
                            {{
                                getFarmTokensPrice(stake.farmTokensList, stake.stakedTokenAddress, stake.stakeIdx, stake.contractId) >= 0
                                    ? getFarmTokensPrice(stake.farmTokensList, stake.stakedTokenAddress, stake.stakeIdx, stake.contractId)
                                    : "0.00"
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-else class="under__data__row">
                <div class="claim">
                    <div class="infrm">
                        <p class="claim__name">claim</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("CLAIM_INFO_?") }}
                            </span>
                        </div>
                    </div>

                    <div v-for="(_, index) in stake.farmTokensList" :key="index" class="claim__text">
                        <div class="claim__flex">
                            <span class="row__one__ball" :class="stake.farmTokensList[index].name"></span>
                            <p class="claim__bring">{{ tokenTicker(stake, stake.farmTokensList[index].name) }}</p>
                        </div>
                        <p class="simple__text">{{ stake.farmTokensRewards >= 0 ? stake.farmTokensRewards.toFixed(4) : "0.0000" }}</p>
                    </div>

                    <!-- <button @click="showClaimModal = true" class="claim__btn"><i class="i-rocket-fill"></i>claim</button> -->
                </div>

                <div class="unstake">
                    <div class="infrm">
                        <p class="unstake__name">unstake</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("UNSTAKE_INFO_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="unstake__big">
                        {{ stake.stakeAmount.toFixed(4) }} <span>{{ tokenTicker(stake, stake.name) }}</span>
                    </p>
                    <p class="unstake__text">{{ lang.get("UNSTAKE_DESC") }}</p>
                    <a class="unstake__btn" target="_blank" :href="`${redirectToExplorer(stake.transaction_id)}`">View transaction</a>
                </div>

                <div class="received">
                    <div class="infrm">
                        <p class="received__name">Received reward</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("Received_Reward_?") }}
                            </span>
                        </div>
                    </div>
                    <div v-for="farmToken in stake.farmTokensList" :key="farmToken.address" class="claim__text">
                        <div class="claim__flex">
                            <span class="row__one__ball" :class="farmToken.name"></span>
                            <p class="claim__bring">{{ tokenTicker(stake, farmToken.name) }}</p>
                        </div>
                        <p class="simple__text">
                            {{
                                findTokenReward(stake.stakedTokenAddress, farmToken.address, stake.stakeIndex, stake.contractId) >= 0
                                    ? findTokenReward(stake.stakedTokenAddress, farmToken.address, stake.stakeIndex, stake.contractId)
                                    : "0.0000"
                            }}
                        </p>
                    </div>

                    <hr />
                    <div class="unstake__sum">
                        <p class="last__unstake__text">approximate cost</p>
                        <p class="last__sum">
                            $
                            {{
                                getFarmTokensPrice(stake.farmTokensList, stake.stakedTokenAddress, stake.stakeIndex, stake.contractId) >= 0
                                    ? getFarmTokensPrice(stake.farmTokensList, stake.stakedTokenAddress, stake.stakeIndex, stake.contractId)
                                    : "0.00"
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    import UnstakeModal from "./modalWindows/UnstakeModal.vue";
    import ClaimModal from "./modalWindows/ClaimModal.vue";
    import conf from "../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import { mapState } from "vuex";
    export default {
        props: ["stake", "claimedStakes"],
        data: function () {
            return {
                showStakeDetails: false,
                lang: new MultiLang(this),
                showUnstakeModal: false,
                showClaimModal: false,
            };
        },
        components: {
            UnstakeModal,
            ClaimModal,
        },
        computed: {
            ...mapState(["currentAddress", "currentBlockchain", "penaltyInfo", "currentRate", "hasReferrerObj"]),
            poolIsEnded() {
                const currentTime = new Date().getTime() / 1000;
                return Config.CONTRACT_VERSION_CONFIG[this.stake.contractId].POOL_END_TIME < currentTime;
            },
        },
        methods: {
            async changeNetwork(symbol) {
                await this.$root.core.changeNetwork(this, symbol.toLowerCase());
            },
            redirectToExplorer(transaction) {
                return this.currentBlockchain === 56
                    ? `https://bscscan.com/tx/${transaction}`
                    : this.currentBlockchain === 1666600000
                    ? `https://explorer.harmony.one/tx/${transaction}`
                    : this.currentBlockchain === 1
                    ? `https://etherscan.io/tx/${transaction}`
                    : "";
            },
            stakeDays(contractId) {
                return Config.CONTRACT_VERSION_CONFIG[contractId].STAKE_DAYS;
            },
            tokenTicker(stake, tokenName) {
                if (!stake.chainId) {
                    return conf[this.currentBlockchain].tokenAliases[tokenName];
                }
                return conf[stake.chainId].tokenAliases[tokenName];
            },
            async claimReward(stake) {
                try {
                    let rawClaimTransaction = await this.$root.core.claimReward(stake.stakeIdx, stake.contractId);
                    this.stakeAmount = stake.tokenAmount;
                    this.tokenName = stake.name;
                    this.showPendingClaim = true;
                    const address = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_ADDRESS.toLowerCase();
                    this.$root.core.forceRestart(this.currentBlockchain, address);
                    let resultClaim = await rawClaimTransaction.wait();
                    if (resultClaim.status === true || resultClaim.status === 1 || resultClaim.status === "1") {
                        window.localStorage.removeItem("stakeInfoTime");
                        this.showPendingClaim = false;
                        this.claimTransactionHash = resultClaim.transactionHash;
                        this.showSuccessClaim = true;
                    } else {
                        this.showPendingClaim = false;
                        this.showError = true;
                    }
                } catch (error) {
                    console.log(error);
                    this.showPendingClaim = false;
                    this.showError = true;
                }
            },
            getTokenPrice(tokenAddress) {
                return this.currentRate ? this.currentRate["0x3ecb96039340630c8b82e5a7732bc88b2aeade82"] : 0; //TODO get price for correct token address
            },
            getFarmTokensPrice(farmTokensList, stakeToken, stakeId, contractId) {
                let total = 0;
                for (let [_, tokenObj] of Object.entries(farmTokensList)) {
                    const rewardTokensAmount = this.findTokenReward(stakeToken, tokenObj.address, stakeId, contractId);

                    total += rewardTokensAmount * Number(this.getTokenPrice(tokenObj.address));
                }
                return total.toFixed(2);
            },
            async unstake(stake) {
                try {
                    let rawUnStakeTransaction = await this.$root.core.unstake(this.currentAddress, stake.stakeIdx, stake.contractId);
                    const address = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_ADDRESS.toLowerCase();
                    this.showPendingUnstake = true;
                    this.$root.core.forceRestart(this.currentBlockchain, address);
                    let resultUnStake = await rawUnStakeTransaction.wait();

                    if (resultUnStake.transactionHash) {
                        this.unstakeTransactionHash = resultUnStake.transactionHash;
                        this.showPendingUnstake = false;
                        this.showSuccessUnstake = true;
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            validReferrer(stake) {
                return stake.referrer !== "0x0000000000000000000000000000000000000000";
            },
            stakeAPYPercentage(stake) {
                if (stake.maxTokensStake === 0) {
                    return "N/A";
                } else {
                    const resCoef = (stake.maxProfitPercent * stake.coefTokens) / stake.maxTokensStake;

                    return this.$root.core.withoutRound(resCoef) === "0.00" ? "N/A" : this.$root.core.withoutRound(resCoef);
                }
            },
            getPenaltyDuration(stake) {
                if (this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()]) {
                    return this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft > 0
                        ? Math.round(this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft / 24 / 3600)
                        : "0";
                }
            },
            getPenaltyPercentForPool(stake) {
                if (this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()]) {
                    return (
                        this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].penaltyPercent /
                        Config.CONTRACT_VERSION_CONFIG[stake.contractId].PENALTY_PRECISION
                    );
                }
            },
            getMaxStakePeriod(contractId, stake) {
                //todo extend when render logic from  all chains added
                const stakeTime = stake.block_timestamp;
                try {
                    if (stake) {
                        if (!stake.chainId) {
                            const daysPassedSinceStart = Math.floor((stakeTime - Config.CONTRACT_VERSION_CONFIG[contractId].POOL_START_TIME) / 3600 / 24);
                            return Config.CONTRACT_VERSION_CONFIG[contractId].STAKE_DAYS - daysPassedSinceStart || 0;
                        }
                        const daysPassedSinceStart = Math.floor(
                            (stakeTime - conf[stake.chainId].CONTRACT_VERSION_CONFIG[contractId].POOL_START_TIME) / 3600 / 24
                        );
                        return conf[stake.chainId].CONTRACT_VERSION_CONFIG[contractId].STAKE_DAYS - daysPassedSinceStart || 0;
                    }
                } catch (error) {
                    console.log(stake, error);
                    debugger;
                }
            },
            getTimePassed(time, claimTime = 0) {
                if (!claimTime) {
                    // const start = new Date(Number.parseInt(`${time.toString() + "000"}`));
                    const now = new Date().getTime() / 1000;

                    const diff = now - time;
                    const minutes = Math.floor(diff / 60);
                    const hours = Math.floor(diff / 3600);
                    const days = Math.floor(diff / 86400);

                    // const startHours = start.getHours();
                    // const startMinutes = start.getMinutes();
                    // const startDay = start.getDate();
                    // const startMonth = start.getMonth();

                    // const nowHours = now.getHours();
                    // const nowMinutes = now.getMinutes();
                    // const nowDay = now.getDate();
                    // const nowMonth = now.getMonth();

                    // const passedHours = nowHours - startHours
                    // const passedMinutes = nowMinutes - startMinutes
                    // const passedDay = nowDay - startDay;
                    // const passedMonth = nowMonth - startMonth

                    if (minutes < 60) {
                        return [minutes, minutes === 1 ? "min" : "mins"];
                    } else if (hours > 0 && hours < 24) {
                        return [hours, hours === 1 ? "hour" : "hours"];
                    } else if (days > 0) {
                        return [days, ""];
                    }
                } else {
                    // const start = new Date(Number.parseInt(`${time.toString() + "000"}`));
                    const now = claimTime;

                    const diff = now - time;
                    const minutes = Math.floor(diff / 60);
                    const hours = Math.floor(diff / 3600);
                    const days = Math.floor(diff / 86400);

                    // const startHours = start.getHours();
                    // const startMinutes = start.getMinutes();
                    // const startDay = start.getDate();
                    // const startMonth = start.getMonth();

                    // const nowHours = now.getHours();
                    // const nowMinutes = now.getMinutes();
                    // const nowDay = now.getDate();
                    // const nowMonth = now.getMonth();

                    // const passedHours = nowHours - startHours
                    // const passedMinutes = nowMinutes - startMinutes
                    // const passedDay = nowDay - startDay;
                    // const passedMonth = nowMonth - startMonth

                    if (minutes < 60) {
                        return [minutes, minutes === 1 ? "min" : "mins"];
                    } else if (hours > 0 && hours < 24) {
                        return [hours, hours === 1 ? "hour" : "hours"];
                    } else if (days > 0) {
                        return [days, ""];
                    }
                }
            },
            findTokenReward(stakeToken, rewardToken, stakeId, contractId) {
                const stake = this.claimedStakes.find(
                    (stk) =>
                        stk.stakedTokenAddress.toLowerCase() === stakeToken.toLowerCase() &&
                        Number(stk.stakeIndex) === Number(stakeId) &&
                        stk.contractId === contractId
                );

                if (stake && stake.rewardPayoutEvents && stake.rewardPayoutEvents.length) {
                    let totalReward = 0;

                    const rewardObjArray = stake.rewardPayoutEvents.filter((rwd) => rwd.rewardTokenAddress.toLowerCase() === rewardToken.toLowerCase());
                    for (let rewardObj of rewardObjArray) {
                        totalReward = totalReward + rewardObj.reward;
                    }
                    return totalReward ? Number(totalReward).toFixed(4) : "0.0000";
                }

                return "0.0000";
            },
            getMaxStakeDays(stake) {
                const daysPassedBeforeStake = Math.floor(
                    (stake.block_timestamp - Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_START_TIME) / 3600 / 24
                );

                return Config.CONTRACT_VERSION_CONFIG[stake.contractId].STAKE_DAYS - daysPassedBeforeStake;
            },
            getDaysTillEnd(stake) {
                const currentTime = new Date().getTime() / 1000;
                const endTime = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_END_TIME;
                return endTime > currentTime ? Math.floor((endTime - currentTime) / 3600 / 24) : 0;
            },
        },
        watch: {
            showClaimModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showUnstakeModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
        },
    };
</script>
