<template>
    <div class="mod">
        <transaction-processing
            :showPendingUnstake="showPendingUnstake"
            :showSuccessUnstake="showSuccessUnstake"
            :showError="showError"
            :unstakeTransactionHash="unstakeTransactionHash"
            :stakeAmount="stake.tokenAmount"
            :showSmallBalance="showSmallBalance"
            :tokenName="stake.name"
            @close="
                (showPendingUnstake = false),
                    (showSuccessUnstake = false),
                    (showSmallBalance = false),
                    (showError = false),
                    (unstakeTransactionHash = ''),
                    (tokenName = '')
            "
        />
        <div class="modal__inner">
            <div class="modal__up">
                <p class="modal__name">Unstake confirmation</p>
                <i @click="$emit('close')" class="i-close-line"></i>
            </div>
            <div class="modal__down">
                <div class="first__block">
                    <p class="could__get">{{ lang.get("STAKE_PAGE_TITLE") }}</p>
                    <div class="row__days__text">
                        <i class="i-question-line"></i>
                        <span class="question-line-text">
                            {{ lang.get("STAKE_AMOUNT_?") }}
                        </span>
                    </div>
                </div>
                <p class="unstake__big">
                    {{ stake.tokenAmount.toFixed(4) }} <span>{{ tokenTicker(stake.name) }}</span>
                </p>
                <div class="modal__reward__you">
                    <div class="name__reward__you">
                        <p class="your__reward">{{ lang.get("REWARD_AMOUNT") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("REWARD_AMOUNT_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="many__name">
                        <div v-for="(farmToken, index) in stake.farmTokensReward" :key="stake.farmTokensList[index].address" class="first__many__name">
                            <div class="block__for__many">
                                <span class="ball__many" :class="stake.farmTokensList[index].name"></span>
                                <p class="many__name__name">{{ tokenTicker(stake.farmTokensList[index].name) }}</p>
                            </div>
                            <p class="simple__text">{{ getSingleTokenReward(farmToken, stake.farmTokensList[index].address).tokenAmount }}</p>
                        </div>
                    </div>
                </div>
                <div v-if="getPenaltyPercent > 0" class="warning">
                    <div class="warr"><i class="i-error-warning-fill"></i></div>
                    <div class="warrning-block">
                        <p class="warning__text">{{ lang.get("PENALTY_DESC") }} {{ getPenaltyDateUntil(stake) }}</p>
                        <a class="learn__more" href="https://bringfinance.medium.com/how-to-earn-max-rewards-and-avoid-penalties-staking-e77a017b4849">{{
                            lang.get("LEARN_MORE")
                        }}</a>
                    </div>
                </div>
                <div class="penalty__block">
                    <div class="penalty__block__text">
                        <p class="penalty__text">{{ lang.get("PENALTY_PERC") }}</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("PENALTY_PERC_?") }}
                            </span>
                        </div>
                    </div>
                    <p class="penalty__number">{{ getPenaltyPercent >= 0 ? getPenaltyPercent : 0 }}%</p>
                </div>
                <div v-if="stake.farmTokensList && stake.farmTokensList.length > 1" class="earn__coust">
                    <div v-if="getPenaltyPercent > 0">
                        <div class="coust__name">
                            <p class="coust__text">{{ lang.get("YOU_EARN_LESS") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("LESS_?") }}
                                </span>
                            </div>
                        </div>
                        <p v-if="getPenaltyPercent > 0" class="coust__all"><span>$</span></p>
                        <p class="any__number">{{ getFarmTokensPrice(stake.farmTokensList) }} USD</p>
                    </div>
                </div>
                <div v-else-if="stake.farmTokensList && stake.farmTokensList.length === 1" class="earn__coust">
                    <div v-if="getPenaltyPercent > 0">
                        <div class="coust__name">
                            <p class="coust__text">{{ lang.get("YOU_EARN_LESS") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("LESS_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="coust__all">
                            <span>$</span>{{ getSingleTokenReward(stake.farmTokensReward[0], stake.farmTokensList[0].address).lostTokenAmountUSD }}
                        </p>
                        <!-- <p class="any__number">{{ getSingleTokenReward(stake.farmTokensReward[0], stake.farmTokensList[0].address).tokenAmountUSD }} USD</p> -->
                    </div>
                </div>
                <div v-if="getPenaltyPercent > 0" class="modal__checkbox">
                    <input class="custom-checkbox" type="checkbox" id="myCheckbox1" v-model="acceptedPenalty" />
                    <label for="myCheckbox1" class="modal__text">{{ lang.get("CHECK_UNSTAKE") }}</label>
                </div>

                <button @click="unstake(stake)" :disabled="!acceptedPenalty" class="make__stake">unstake</button>
            </div>
        </div>
    </div>
</template>
<script>
    import MultiLang from "@/core/multilang";
    import { mapState } from "vuex";
    import conf from "../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import TransactionProcessing from "./TransactionProcessing.vue";
    export default {
        components: {
            TransactionProcessing,
        },
        props: ["stake"],
        data: function () {
            return {
                lang: new MultiLang(this),
                acceptedPenalty: false,
                showPendingUnstake: false,
                showSuccessUnstake: false,
                showError: false,
                tokenName: "",
                unstakeTransactionHash: "",
                showSmallBalance: false,
            };
        },
        computed: {
            ...mapState(["penaltyInfo", "currentAddress", "userCoinBalance", "currentRate"]),
            getPenaltyPercent() {
                return Number(
                    Number(this.penaltyInfo[this.stake.contractId][this.stake.stakedTokenAddress.toLowerCase()].penaltyPercent) /
                        Number(Config.CONTRACT_VERSION_CONFIG[this.stake.contractId].PENALTY_PRECISION)
                ).toFixed(2);
            },
        },
        mounted() {
            let _this = this;

            setTimeout(async function init() {
                if (_this.getPenaltyPercent > 0) {
                    _this.acceptedPenalty = false;
                } else {
                    _this.acceptedPenalty = true;
                }
            }, 300);
        },
        methods: {
            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },
            getTokenPrice(tokenAddress) {
                return this.currentRate && this.currentRate[tokenAddress.toLowerCase()] ? this.currentRate[tokenAddress.toLowerCase()] : 0;
            },
            getFarmTokensPrice(farmTokensList) {
                if (this.getPenaltyPercent === 0) {
                    let total = 0;
                    for (let [index, tokenObj] of Object.entries(farmTokensList)) {
                        const rewardTokensAmount = Number(this.stake.farmTokensReward[index]);

                        total += rewardTokensAmount * Number(this.getTokenPrice(tokenObj.address));
                    }
                    return total.toFixed(2);
                } else {
                    let total = 0;
                    for (let [index, tokenObj] of Object.entries(farmTokensList)) {
                        let rewardTokensAmount = Number(this.stake.farmTokensReward[index]);
                        rewardTokensAmount *= (100 - this.getPenaltyPercent) / 100;

                        total += rewardTokensAmount * Number(this.getTokenPrice(tokenObj.address));
                    }
                    return total.toFixed(2);
                }
            },
            async unstake(stake) {
                try {
                    const res = await this.$root.core.estimateGas("unstake", stake.contractId, {
                        stakeId: stake.stakeIdx,
                    });
                    const fee = (Number(res) * Config.DEFAULT_GAS_PRICE_GWEI * 10 ** 9) / 10 ** 18;

                    if (fee > this.userCoinBalance) {
                        this.showSmallBalance = true;
                        return;
                    }
                    this.showPendingUnstake = true;
                    let rawUnStakeTransaction = await this.$root.core.unstake(this.currentAddress, stake.stakeIdx, stake.contractId);
                    const address = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_ADDRESS.toLowerCase();

                    this.$root.core.forceRestart(this.currentBlockchain, address);

                    let resultUnStake = await rawUnStakeTransaction.wait();

                    if (resultUnStake.transactionHash) {
                        setTimeout(() => {
                            this.$emit("close");
                        }, 5000);
                        this.unstakeTransactionHash = resultUnStake.transactionHash;
                        this.showPendingUnstake = false;
                        this.showSuccessUnstake = true;
                    }
                } catch (error) {
                    this.$emit("close");
                    this.showPendingUnstake = false;
                    this.showError = true;
                }
            },
            getSingleTokenReward(farmToken, farmTokenAddress) {
                if (this.getPenaltyPercent !== 0) {
                    const tokenAmount = ((farmToken * (100 - this.getPenaltyPercent)) / 100).toFixed(4);
                    const lostTokenAmount = (farmToken - tokenAmount).toFixed(4);
                    const tokenAmountUSD = (tokenAmount * this.getTokenPrice(farmTokenAddress)).toFixed(2);
                    const lostTokenAmountUSD = (lostTokenAmount * this.getTokenPrice(farmTokenAddress)).toFixed(2);
                    return {
                        tokenAmount,
                        lostTokenAmount,
                        tokenAmountUSD,
                        lostTokenAmountUSD,
                    };
                } else {
                    const tokenAmount = farmToken.toFixed(4);
                    const tokenAmountUSD = (farmToken * this.getTokenPrice(farmTokenAddress)).toFixed(2);
                    return {
                        tokenAmount,
                        tokenAmountUSD,
                    };
                }
            },
            getPenaltyDuration(stake) {
                if (this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()]) {
                    return this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft > 0
                        ? Math.round(this.penaltyInfo[stake.contractId][stake.stakedTokenAddress.toLowerCase()].timeLeft / 24 / 3600)
                        : "0";
                }
            },
            getPenaltyDateUntil(stake) {
                var dt = new Date();
                dt.setDate(dt.getDate() + this.getPenaltyDuration(stake));
                return dt.toLocaleDateString("en-UK");
            },
        },
    };
</script>
