<template>
    <div class="stake-bg">
        <div class="responsive stake-bg-des">
            <div class="referal">
                <div class="discription">
                    <div class="discription__wrapper">
                        <div class="stake__icon"></div>
                        <h1>{{ lang.get("STAKE_PAGE_TITLE") }}</h1>
                    </div>
                    <p class="referal_text">{{ lang.get("STAKE_DESC") }}</p>
                </div>
                <div class="patric__foto"></div>
            </div>

            <div class="settings">
                <div class="for_me">
                    <div @click="changeCurrentType($event)" class="settings__left">
                        <button class="first__button" value="all" :class="{ activ: contractType === 'all' }">All</button>
                        <button class="button__two" value="liquidity" :class="{ activ: contractType === 'liquidity' }">Liquidity staking</button>
                        <button class="button__there" value="farming" :class="{ activ: contractType === 'farming' }">Farming</button>
                    </div>
                    <div class="settings__center">
                        <button @click="isLive = true" class="button__live" :class="{ activ: isLive === true }">Live</button>
                        <button @click="isLive = false" class="button__finish" :class="{ activ: isLive === false }">Finished</button>
                    </div>
                </div>
                <div class="settings__right">
                    <div
                        v-if="isLive === true"
                        class="dropdown"
                        @click.stop="showSortBy = !showSortBy"
                        :class="{ active: showSortBy, 'drop-active': showSortBy }"
                    >
                        <div class="dropdown-select">
                            <span v-if="sortBy === ''" class="select">Sort by</span>
                            <span v-else class="select">{{
                                sortBy === "apr" ? "APR" : sortBy === "staked" ? "Days Staked" : sortBy === "completion" ? "Ends  with" : "A-Z"
                            }}</span>

                            <i class="i-arrow-drop-down-line"></i>
                        </div>
                        <div class="dropdown-list">
                            <div @click.stop="(sortBy = 'apr'), (showSortBy = !showSortBy)" class="dropdown-list__item up">APR</div>
                            <div @click.stop="(sortBy = 'staked'), (showSortBy = !showSortBy)" class="dropdown-list__item">Days Staked</div>
                            <div @click.stop="(sortBy = 'completion'), (showSortBy = !showSortBy)" class="dropdown-list__item down">Ends with</div>
                            <div @click.stop="(sortBy = 'alphabetic'), (showSortBy = !showSortBy)" class="dropdown-list__item down">A-Z</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- таблица -->
            <div class="row stake__style">
                <div class="row__name">
                    <div class="row__stake-name">
                        <span>{{ lang.get("TOKEN_NAME") }}</span>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("TOKEN_?") }}
                            </span>
                        </div>
                    </div>
                    <div class="for__aap">
                        <div class="app__first-stake">
                            <p class="row__apr-stake">Stake amount</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text"> The amount of tokens for specific stake </span>
                            </div>
                        </div>
                        <div class="app__fifth-stake-chain">
                            <p class="row__reward">reward</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("REWARD_RECEIVED_?") }}
                                </span>
                            </div>
                        </div>
                        <div class="app__first-stake-chain">
                            <p class="row__apr">APR</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("APR_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <div class="aap__second-stake">
                            <p class="row__days">{{ lang.get("DAYS_STAKED") }}</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("DAYS_STAKED_STAKE_?") }}
                                </span>
                            </div>
                        </div>
                        <div class="app__fifth-stake">
                            <p class="row__reward">blockchain</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("REWARD_RECEIVED_?") }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showLoader" class="table__loader">
                <i class="i-loader-4-line"></i>
            </div>
            <div
                v-if="
                    (isLive === true && stakesInfo !== null && activeStakes.length === 0 && currentAddress !== '0x0000000000000000000000000000000000000000') ||
                    (isLive === true &&
                        stakesInfoForContractType &&
                        stakesInfoForContractType.length === 0 &&
                        currentAddress !== '0x0000000000000000000000000000000000000000')
                "
                class="empty__card how__to__earn"
                data-cy="wrapper-stake"
            >
                <div class="search-icon"></div>
                <p class="first__cat__text">{{ lang.get("NOTHING_STAKED") }}</p>

                <p @click="$router.push({ name: 'pool' })" class="two__cat__text">{{ lang.get("HOW_TO_STAKE") }}</p>
            </div>
            <div v-if="currentAddress === '0x0000000000000000000000000000000000000000'" class="empty__card how__to__earn">
                <div class="search-icon"></div>
                <p class="first__cat__text">{{ lang.get("NO_WALLET_NO_STAKES") }}</p>
                <button @click="$emit('connectWallet')" class="for__hide_one">Connect Wallet</button>
            </div>

            <div
                v-if="claimedStakes.length === 0 && isLive === false && currentAddress !== '0x0000000000000000000000000000000000000000'"
                class="empty__card how__to__earn"
            >
                <!-- <div class="cat"></div> -->

                <div class="search-icon"></div>
                <p v-if="(claimedStakes && claimedStakes.length === 0) || unsupportedChainId()" class="first__cat__text">
                    {{ lang.get("NOTHING_FINISHED_STAKED") }}
                </p>
                <p @click="$router.push({ name: 'pool' })" class="two__cat__text">{{ lang.get("HOW_TO_STAKE") }}</p>
            </div>
            <stake-details-card v-else v-for="stake in activeStakes" :key="stake.block_timestamp" :stake="stake" :claimedStakes="claimedStakes" />
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    import TransactionProcessing from "../../../components/modalWindows/TransactionProcessing.vue";
    import { mapState } from "vuex";
    // import CabinetNavigation from "../../../components/CabinetNavigation.vue";
    import conf from "../../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import Loader from "../../../components/Loader.vue";
    import StakeDetailsCard from "../../../components/StakeDetailsCard.vue";

    export default {
        components: { TransactionProcessing, Loader, StakeDetailsCard },
        name: "Stake",
        data: function () {
            return {
                lang: new MultiLang(this),
                showPendingClaim: false,
                showPendingUnstake: false,
                showSuccessClaim: false,
                showSuccessUnstake: false,
                showSortBy: false,
                stakeAmount: 0,
                tokenName: "",
                unstakeTransactionHash: "",
                claimTransactionHash: "",
                contractType: "all",
                stakesInfoForContractType: null,
                allEvents: [],
                claimedStakes: [],
                loader: true,
                isLive: true,
                sortBy: "",
                isSupported: false,
            };
        },

        computed: {
            ...mapState(["stakesInfo", "currentAddress", "farmTokens", "refNumber", "currentBlockchain", "penaltyInfo", "rewardPageData"]), //TODO set user stake info to store
            showLoader() {
                const walletChoice = window.localStorage.getItem("selectedWallet");
                if (!walletChoice) {
                    return false;
                }
                if (walletChoice === "metamask" && window.ethereum && window.ethereum.chainId !== null) {
                    this.isSupported = Boolean(conf.SUPPORTED_BLOCKCHAINS.find((networkId) => Number(networkId) === Number(window.ethereum.chainId)));
                    // console.log("wrong set supported,", Number(window.ethereum.chainId), conf.SUPPORTED_BLOCKCHAINS);
                    return this.isSupported === false ? false : this.stakesInfo === null ? true : false;
                } else if (walletChoice === "onto" && window.onto && window.onto.chainId !== null) {
                    this.isSupported = Boolean(conf.SUPPORTED_BLOCKCHAINS.find((networkId) => Number(networkId) === Number(window.onto.chainId)));
                    // console.log("wrong set supported,", Number(window.ethereum.chainId), conf.SUPPORTED_BLOCKCHAINS);
                    return this.isSupported === false ? false : this.stakesInfo === null ? true : false;
                } else if (walletChoice === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    this.isSupported = Boolean(conf.SUPPORTED_BLOCKCHAINS.find((networkId) => Number(networkId) === Number(WC_Obj?.chainId)));
                    // console.log("wrong set supported,", Number(window.ethereum.chainId), conf.SUPPORTED_BLOCKCHAINS);
                    return this.isSupported === false ? false : this.stakesInfo === null ? true : false;
                }
                return false;
            },
            activeStakes() {
                if (!this.stakesInfoForContractType) {
                    return [];
                }

                //    if(this.sortBy === ''){
                if (this.contractType === "all" && this.isLive === true) {
                    return this.sortArray(this.stakesInfoForContractType.filter((el) => el.isActive === true));
                } else if (this.contractType === "all" && this.isLive === false) {
                    return this.sortArray(this.claimedStakes.filter((el) => el.isActive === false && el.name));
                } else if (this.contractType === "liquidity" && this.isLive === true) {
                    return this.sortArray(this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType));
                } else if (this.contractType === "liquidity" && this.isLive === false) {
                    return this.sortArray(this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name));
                } else if (this.contractType === "farming" && this.isLive === false) {
                    return this.sortArray(this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name));
                } else if (this.contractType === "farming" && this.isLive === true) {
                    return this.sortArray(this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType));
                }
                //    }else if(this.sortBy === 'apr'){
                //         if (this.contractType === "all" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true);
                //     } else if (this.contractType === "all" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.name);
                //     } else if (this.contractType === "liquidity" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     } else if (this.contractType === "liquidity" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     }

                //    }else if (this.sortBy === 'staked'){
                //         if (this.contractType === "all" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true);
                //     } else if (this.contractType === "all" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.name);
                //     } else if (this.contractType === "liquidity" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     } else if (this.contractType === "liquidity" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     }

                //    }else if (this.sortBy === 'completion'){
                //         if (this.contractType === "all" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true);
                //     } else if (this.contractType === "all" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.name);
                //     } else if (this.contractType === "liquidity" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     } else if (this.contractType === "liquidity" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     }

                //    }else if (this.sortBy === 'alphabetic'){
                //         if (this.contractType === "all" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true);
                //     } else if (this.contractType === "all" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.name);
                //     } else if (this.contractType === "liquidity" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     } else if (this.contractType === "liquidity" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === false) {
                //         return this.claimedStakes.filter((el) => el.isActive === false && el.contractType === this.contractType && el.name);
                //     } else if (this.contractType === "farming" && this.isLive === true) {
                //         return this.stakesInfoForContractType.filter((el) => el.isActive === true && el.contractType === this.contractType);
                //     }

                //    }
            },
        },
        methods: {
            sortArray(array) {
                if (this.sortBy === "") {
                    return array;
                } else if (this.sortBy === "apr") {
                    array.sort((a, b) => {
                        const aValue = (a.maxProfitPercent * a.coefTokens) / a.maxTokensStake;
                        const bValue = (b.maxProfitPercent * b.coefTokens) / b.maxTokensStake;
                        return bValue - aValue;
                    });
                    return array;
                } else if (this.sortBy === "staked") {
                    array.sort((a, b) => a.block_timestamp - b.block_timestamp);
                    return array;
                } else if (this.sortBy === "completion") {
                    const currentTime = new Date().getTime() / 1000;

                    array.sort((a, b) => {
                        const configA = conf[a.chainId ? a.chainId : this.currentBlockchain];
                        const configB = conf[b.chainId ? b.chainId : this.currentBlockchain];
                        const POOL_END_TIME_A = configA.CONTRACT_VERSION_CONFIG[a.contractId].POOL_END_TIME - a.block_timestamp;
                        const POOL_END_TIME_B = configB.CONTRACT_VERSION_CONFIG[b.contractId].POOL_END_TIME - b.block_timestamp;

                        if (POOL_END_TIME_A > POOL_END_TIME_B) {
                            return 1;
                        } else if (POOL_END_TIME_A < POOL_END_TIME_B) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    return array;
                } else if (this.sortBy === "alphabetic") {
                    array.sort((a, b) => a.tag.localeCompare(b.tag));
                    return array;
                }
            },
            hideOption(event) {
                this.showSortBy = false;
                return;

                // let _this = this;
                // try {
                //     event.stopPropagation();

                //     var el = event.target.parentNode;
                //     el.style.display = "none";
                //     setTimeout(function () {
                //         // _this.showSortBy = false;
                //         el.style.removeProperty("display");
                //     }, 300);
                // } catch (error) {
                //     console.log(error);
                // }
            },
            unsupportedChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet === "metamask" && window.ethereum) {
                    if (Number(window.ethereum.chainId) > 0) {
                        return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.ethereum.chainId || 0)) < 0;
                    } else {
                        setTimeout(this.unsupportedChainId, 300);
                        return true;
                    }
                } else if (selectedWallet === "onto" && window.onto) {
                    if (Number(window.onto.chainId) > 0) {
                        return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.onto.chainId || 0)) < 0;
                    } else {
                        setTimeout(this.unsupportedChainId, 300);
                        return true;
                    }
                } else if (selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    return conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(WC_Obj?.chainId)) < 0;
                }
                return true;
            },
            changeCurrentType(event) {
                this.contractType = event.target.value;
                if (this.contractType === "all") {
                    this.stakesInfoForContractType = this.stakesInfo;
                } else {
                    this.stakesInfoForContractType = this.stakesInfo.filter((el) => el.contractType === event.target.value);
                }
            },

            hasClaimMethod(stake) {
                return this.$root.core[stake.contractId].claimReward ? true : false;
            },

            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },

            getTimePassed(time) {
                // const start = new Date(Number.parseInt(`${time.toString() + "000"}`));
                const now = new Date().getTime() / 1000;

                const diff = now - time;
                const minutes = Math.floor(diff / 60);
                const hours = Math.floor(diff / 3600);
                const days = Math.floor(diff / 86400);

                // const startHours = start.getHours();
                // const startMinutes = start.getMinutes();
                // const startDay = start.getDate();
                // const startMonth = start.getMonth();

                // const nowHours = now.getHours();
                // const nowMinutes = now.getMinutes();
                // const nowDay = now.getDate();
                // const nowMonth = now.getMonth();

                // const passedHours = nowHours - startHours
                // const passedMinutes = nowMinutes - startMinutes
                // const passedDay = nowDay - startDay;
                // const passedMonth = nowMonth - startMonth

                if (minutes < 60) {
                    return [minutes, minutes === 1 ? "min" : "mins"];
                } else if (hours > 0 && hours < 24) {
                    return [hours, hours === 1 ? "hour" : "hours"];
                } else if (days > 0) {
                    return [days, days === 1 ? "day" : "days"];
                }
            },
            getMaxStakeDays(stake) {
                const daysPassedBeforeStake = Math.floor(
                    (stake.block_timestamp - Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_START_TIME) / 3600 / 24
                );

                return Config.CONTRACT_VERSION_CONFIG[stake.contractId].STAKE_DAYS - daysPassedBeforeStake;
            },
            getDaysTillEnd(stake) {
                const currentTime = new Date().getTime() / 1000;
                const endTime = Config.CONTRACT_VERSION_CONFIG[stake.contractId].POOL_END_TIME;
                return endTime > currentTime ? (endTime - currentTime) / 3600 / 24 : 0;
            },
        },

        mounted() {
            this.lang.init();
            let _this = this;
            window.scrollTo(0, 0);
            let errorCounter = 0;
            setTimeout(async function tick() {
                _this.claimAndUnstakeEvents = [];
                try {
                    if (_this.stakesInfo === null) {
                        if (
                            (window.localStorage.getItem("selectedWallet") === "metamask" && !window.ethereum) ||
                            (window.localStorage.getItem("selectedWallet") === "onto" && !window.onto)
                        ) {
                            console.log("case to return?");
                            return;
                        }
                        errorCounter++;
                        throw Error("no stake info");
                    }
                    if (_this.contractType === "all") {
                        _this.stakesInfoForContractType = _this.stakesInfo;
                    } else {
                        _this.stakesInfoForContractType = _this.stakesInfo.filter((el) => el.contractType === _this.contractType);
                    }
                    _this.allEvents = [];
                    if (_this.rewardPageData !== undefined) {
                        //geting events from objects
                        for (let [contract, allEvents] of Object.entries(_this.rewardPageData)) {
                            _this.allEvents.push(...Object.values(allEvents));
                        }
                        //filter an array to exclude active stakes
                        _this.claimedStakes = [];
                        const currentTime = Math.floor(new Date().getTime() / 1000);

                        _this.claimedStakes = _this.allEvents.filter((el) => {
                            try {
                                if (el.unstakeTime && el.unstakeTime > 0) {
                                    return true;
                                } else if (el.claimEvents && el.claimEvents.length > 0) {
                                    return true;
                                }
                                // else if (el.contractId && currentTime > Config.CONTRACT_VERSION_CONFIG[el.contractId].POOL_END_TIME) {
                                //     el.isExpired = true;
                                //     return true;
                                // }
                            } catch (error) {
                                console.log(error);
                                console.log(el);
                            }
                        });
                        _this.loader = false;
                    }

                    setTimeout(() => {
                        if (!_this.currentAddress || _this.currentAddress === "0x0000000000000000000000000000000000000000") {
                            _this.loader = false;
                        }
                    }, 1200);

                    const inactiveStakeInfoFull = _this.claimedStakes.map((el) => {
                        let token = Config.stakeTokens.find(
                            (token) => token.address.toLowerCase() === el.stakedTokenAddress.toLowerCase() && el.contractId === token.contractId
                        );

                        return {
                            ...el,
                            name: token.name,
                            farmTokensList: token.farmTokensList,
                            farmTokensRewards: el.claimEvents,
                        };
                    });

                    const unstakedEvents = inactiveStakeInfoFull
                        .filter(
                            (event) => event.isActive === false
                            // || event.isExpired === true
                        )

                        .sort((a, b) => {
                            if (b.unstakeTime && a.unstakeTime) {
                                return b.unstakeTime - a.unstakeTime;
                            }
                            // else if (b.stakeStartTime && a.stakeStartTime) {
                            //     return b.stakeStartTime - a.stakeStartTime;
                            // }
                        });
                    // const claimedEvents = inactiveStakeInfoFull.filter((event) => event.isActive === true).sort((a, b) => b.claimTime - a.claimTime);
                    _this.claimedStakes.push(...unstakedEvents);

                    setTimeout(tick, 3000);
                } catch (ex) {
                    console.log(ex);

                    setTimeout(tick, 300);
                }
            }, 300);
        },
        watch: {
            sortBy: function () {
                try {
                    if (this.sortBy === "apr") {
                        this.stakesInfoForContractType.sort((a, b) => {
                            const aValue = (a.maxProfitPercent * a.coefTokens) / a.maxTokensStake;
                            const bValue = (b.maxProfitPercent * b.coefTokens) / b.maxTokensStake;
                            return bValue - aValue;
                        });
                    } else if (this.sortBy === "staked") {
                        this.stakesInfoForContractType.sort((a, b) => a.block_timestamp - b.block_timestamp);
                    } else if (this.sortBy === "completion") {
                        const currentTime = new Date().getTime() / 1000;

                        this.stakesInfoForContractType.sort((a, b) => {
                            const configA = conf[a.chainId ? a.chainId : this.currentBlockchain];
                            const configB = conf[b.chainId ? b.chainId : this.currentBlockchain];
                            const POOL_END_TIME_A = configA.CONTRACT_VERSION_CONFIG[a.contractId].POOL_END_TIME - a.block_timestamp;
                            const POOL_END_TIME_B = configB.CONTRACT_VERSION_CONFIG[b.contractId].POOL_END_TIME - b.block_timestamp;

                            if (POOL_END_TIME_A > POOL_END_TIME_B) {
                                return 1;
                            } else if (POOL_END_TIME_A < POOL_END_TIME_B) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                    } else if (this.sortBy === "alphabetic") {
                        this.stakesInfoForContractType.sort((a, b) => a.tag.localeCompare(b.tag));
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        },
    };
</script>
