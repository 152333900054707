<template>
    <div>
        <!-- PENDING -->
        <div v-if="showPendingStake" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_PROCESSING") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="progress">
                        <div class="progress__loader">
                            <i class="i-loader-4-line"></i>
                        </div>
                        <p class="progress__text__one">{{ lang.get("YOU_STAKING") }} {{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}<br /></p>
                        <p class="progress__text__two">{{ lang.get("WAIT_FOR_BBLOCK") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPendingClaim" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_PROCESSING") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="progress">
                        <div class="progress__loader">
                            <i class="i-loader-4-line"></i>
                        </div>
                        <p class="progress__text__one">{{ lang.get("YOU_CLAIMING") }} {{ tokenTicker(tokenName) }}<br /></p>
                        <p class="progress__text__two">{{ lang.get("WAIT_FOR_BBLOCK") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPendingUnstake" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_PROCESSING") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="progress">
                        <div class="progress__loader">
                            <i class="i-loader-4-line"></i>
                        </div>
                        <p class="progress__text__one">{{ lang.get("YOU_UNSTAKING") }} {{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}<br /></p>
                        <p class="progress__text__two">{{ lang.get("WAIT_FOR_BBLOCK") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPendingApprove" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_PROCESSING") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="progress">
                        <div class="progress__loader">
                            <i class="i-loader-4-line"></i>
                        </div>
                        <p class="progress__text__one">{{ lang.get("YOU_APPROVING") }} {{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}<br /></p>
                        <p class="progress__text__two">{{ lang.get("WAIT_FOR_BBLOCK") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- SUCCESS -->
        <div v-if="showSuccessApprove" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_CONFIRM") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-checkbox-circle-line"></i>
                        <p class="confirm__text">{{ lang.get("STAKE_APPROVE_SUCCESS") }}&nbsp;{{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSuccessStake" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_CONFIRM") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-checkbox-circle-line"></i>
                        <p class="confirm__text">You have successfully staked {{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}!</p>
                        <!-- <button class="make__stake">make stake</button> -->
                        <br />
                        <p>Redirecting you to the Stake page...</p>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSuccessClaim" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_CONFIRM") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-checkbox-circle-line"></i>
                        <p class="confirm__text">you have successfully claimed reward for {{ tokenTicker(tokenName) }}</p>
                        <button class="make__stake">
                            <a target="_blank" :href="`${redirectToExplorer(claimTransactionHash)}`">View transaction</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSuccessUnstake" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_CONFIRM") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-checkbox-circle-line"></i>
                        <p class="confirm__text">you have successfully unstaked {{ stakeAmount }}&nbsp;{{ tokenTicker(tokenName) }}</p>
                        <button class="make__stake">
                            <a target="_blank" :href="`${redirectToExplorer(unstakeTransactionHash)}`">View transaction</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- FAILS -->
        <div v-if="showRejection" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_FAILED") }}</p>
                    <i @click="$emit('close')" class="i-close-circle-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <p class="confirm__text">{{ lang.get("TRANS_FAILED_MESSAGE") }}</p>
                        <button class="make__stake">cancel</button>
                        <button class="unstake__modal__btn">cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPoolLimitReached" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_STATUS") }}</p>
                    <i @click="$emit('close')" class="i-close-circle-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-close-circle-line"></i>
                        <p class="confirm__text">Stake amount exceeds pool limit. Max stake amount is {{ stakeAmount }}<br /></p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showError" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_STATUS") }}</p>
                    <i @click="$emit('close')" class="i-close-circle-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-close-circle-line"></i>
                        <p class="confirm__text">{{ lang.get("TRANS_FAILED_MESSAGE") }}<br /></p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSmallBalance" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("TRANS_STATUS") }}</p>
                    <i @click="$emit('close')" class="i-close-circle-line"></i>
                </div>
                <div class="modal__down">
                    <div class="confirm">
                        <i class="i-close-circle-line"></i>
                        <p class="confirm__text">Not enough funds to cover transaction fees<br /></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import MultiLang from "@/core/multilang";
    import { mapState } from "vuex";
    import conf from "../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")];
    export default {
        name: "TransactionProcessing",
        props: [
            "showPendingApprove",
            "showPendingStake",
            "showPendingClaim",
            "showPendingUnstake",
            "showSuccessApprove",
            "showSuccessStake",
            "showSuccessClaim",
            "showSuccessUnstake",
            "stakeAmount",
            "tokenName",
            "showRejection",
            "showError",
            "showPoolLimitReached",
            "unstakeTransactionHash",
            "claimTransactionHash",
            "showSmallBalance",
        ],
        data: function () {
            return {
                lang: new MultiLang(this),
            };
        },
        mounted() {
            this.lang.init();
        },
        computed: {
            ...mapState(["currentBlockchain"]),
        },
        methods: {
            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },
            redirectToExplorer(transaction) {
                return this.currentBlockchain === 56
                    ? `https://bscscan.com/tx/${transaction}`
                    : this.currentBlockchain === 1666600000
                    ? `https://explorer.harmony.one/tx/${transaction}`
                    : this.currentBlockchain === 1
                    ? `https://etherscan.io/tx/${transaction}`
                    : "";
            },
        },
    };
</script>
